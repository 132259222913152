import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import MainRouter from './router/MainRouter'

import store from './store'

const root = (
  <Provider store={store}>
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(root, document.getElementById('root'))
