import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import {createLogger} from 'redux-logger'
import rootReducer from '../reducers'

const middlewares = [thunk]

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger()

  // middlewares.push(logger)
}

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
)

const store = createStore(
  rootReducer,
  enhancer,
)

export default store
