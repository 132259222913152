import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import FeedbackWidget from '../containers/FeedbackWidget'
import config from '../services/config'

const NotFound = () => <h1>Not Found</h1>
const NotFoundRoute = <Route component={NotFound} />
const RedirectToLandingPage = <Redirect to="/docs" />

const MainRoutes = () => (
  <Switch>
    <Route exact path="/feedback-widget/:appId" component={FeedbackWidget} />
    <Route
      exact
      path="/"
      render={() => {
        window.location = '/docs'
      }}
    />
    {config.isProduction ? RedirectToLandingPage : NotFoundRoute}
  </Switch>
)

export default MainRoutes
