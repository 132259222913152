import React, {useState, useRef} from 'react'
import _ from 'lodash'
import {t} from '../../services/i18n'

const getAnswerFromTask = (task, value) => ({
  id: task.id,
  type: task.type,
  question: task.question,
  answer: value,
})

const SingleChoiceTask = ({task, onTaskEnd}) => {
  const Option = ({option}) => (
    <button
      type="button"
      onClick={() => onTaskEnd(getAnswerFromTask(task, option.value))}
      className="pfm-option theme-pfm-option-purple"
    >
      {option.label}
    </button>
  )

  return (
    <div className="brutal-pfm-block">
      <div className="pfm-question">{task.question}</div>
      <div className="brutal-pfm-options">
        {task.options.map(option => <Option key={option.value} option={option} />)}
      </div>
    </div>
  )
}

const InputQuestionTask = ({task, onTaskEnd}) => {
  const inputRef = useRef()

  const onSubmit = (e) => {
    e.preventDefault()
    onTaskEnd(getAnswerFromTask(task, inputRef.current.value))
    inputRef.current.value = ''
  }

  const buttonValue = task.buttonValue || 'Reply'

  return (
    <div className="brutal-pfm-block fade-in">
      <form onSubmit={onSubmit}>
        <div className="pfm-question">{task.question}</div>
        <input defaultValue="" placeholder={t('inputQuestionTaskPlaceholder')} ref={inputRef} />
        <button className="btn btn-theme-purple" type="submit">{buttonValue}</button>
      </form>
    </div>
  )
}

const InfoTextTask = ({task, onTaskEnd}) => {
  const onSubmit = () => onTaskEnd()
  const buttonValue = task.buttonValue || 'Close '

  return (
    <div className="brutal-pfm-block fade-in">
      <div className="pfm-question">{task.question}</div>
      <button className="btn btn-theme-purple" type="button" onClick={onSubmit}>{buttonValue}</button>
    </div>
  )
}

const Task = (props) => {
  const {task} = props

  if (!task) {
    console.error('No task provided')
    return null
  }

  if (task.type === 'single-choice') {
    return <SingleChoiceTask {...props} />
  }

  if (task.type === 'input-question') {
    return <InputQuestionTask {...props} />
  }

  if (task.type === 'info-text') {
    return <InfoTextTask {...props} />
  }

  return 'INVALID TYPE OF TASK'
}

const Tasks = ({tasks = [], onTasksEnd}) => {
  const [currentTaskIndex, setCurrentTask] = useState(0)
  const [answers, setAnswers] = useState([])

  const onTaskEnd = (value) => {
    const newAnswers = [...answers, value]
    const newCurrentTaskIndex = currentTaskIndex + 1

    if (newCurrentTaskIndex >= tasks.length) {
      return _.isFunction(onTasksEnd) && onTasksEnd(newAnswers)
    }

    setAnswers(newAnswers)
    return setCurrentTask(newCurrentTaskIndex)
  }


  const currentTask = tasks[currentTaskIndex]
  const isLastTask = currentTaskIndex >= tasks.length - 1

  return (
    <Task
      key={_.get(currentTask, 'id', null)}
      task={currentTask}
      onTaskEnd={onTaskEnd}
      isLastTask={isLastTask}
    />
  )
}


export default Tasks
