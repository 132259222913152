import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import qs from 'query-string'
import {t} from '../../services/i18n'
import './index.css'
import Campaign from '../../components/Campaign'

import {
  setAppId, fetchWidget, setIsVisible, submitAnswers, setUserId,
} from '../../actions'

class FeedbackWidget extends Component {
  async componentDidMount() {
    const {dispatch, match, location} = this.props
    const {appId} = match.params
    const {userId} = qs.parse(location.search)

    dispatch(setAppId(appId))
    dispatch(setUserId(userId))
    await dispatch(fetchWidget(appId))

    const {widget} = this.props

    dispatch(
      setIsVisible(widget.shouldDisplay && widget.campaign),
    )
  }

  hideWidget = () => {
    const {dispatch} = this.props
    dispatch(
      setIsVisible(false),
    )
  }

  onClickClose = () => this.hideWidget()

  onCampaignEnd = () => {
    this.hideWidget()
  }

  submitAnswers = (answers) => {
    const {dispatch} = this.props
    const campaignId = _.get(this.props, 'widget.campaign.id')

    dispatch(
      submitAnswers({
        campaignId,
        answers,
      }),
    )
  }

  render() {
    const {isVisible, widget} = this.props

    if (!isVisible || !widget || !widget.campaign) {
      return null
    }

    return (
      <div className="brutal-container theme-containter-purple slide-in-bottom">
        <span className="pfm-hide" onClick={this.onClickClose}>{t('close')}</span>
        <Campaign
          campaign={widget.campaign}
          productName={widget.productName}
          onCampaignEnd={this.onCampaignEnd}
          submitAnswers={this.submitAnswers}
        />
      </div>
    )
  }
}


const mapStateToProps = ({appId, isVisible, widget}) => ({appId, isVisible, widget: widget.payload})

export default connect(mapStateToProps)(FeedbackWidget)
