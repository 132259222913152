import React from 'react'
import PFMCampaign from './PFMCampaign'

const Campaign = ({campaign, ...extraProps}) => {
  if (!campaign) {
    return null
  }

  if (campaign.type === 'pfm') {
    return <PFMCampaign {...extraProps} />
  }

  return null
}

export default Campaign
