import React, {useState} from 'react'
import Tasks from '../Tasks'

const createPFMTasks = (productName = 'this product') => [
  {
    id: 'how-would-you-feel',
    type: 'single-choice',
    question: `How would you feel if you could no longer use ${productName}?`,
    options: [
      {
        type: 'default-option',
        value: 'very-disappointed',
        label: 'Very disapppointed',
      },
      {
        type: 'default-option',
        value: 'somewhat-disappointed',
        label: 'Somewhat disappointed',
      },
      {
        type: 'default-option',
        value: 'not-disappointed',
        label: 'Not disappointed',
      },
    ],
  },
  {
    id: 'type-of-people-would-benefit',
    type: 'input-question',
    question: `1️⃣ What type of people do you think would most likely benefit from ${productName}?`,
    buttonValue: 'Reply',
  },
  {
    id: 'main-benefit',
    type: 'input-question',
    question: `2️⃣  In your opinion, what's the main benefit  ${productName} provides?`,
    buttonValue: 'Reply',
  },
  {
    id: 'how-can-we-improve',
    type: 'input-question',
    question: `3️⃣ Last, but not least. How can ${productName} improve?`,
  },
]

const thankYouTask = {
  type: 'info-text',
  question: 'Thank you for giving your feedback!',
  buttonValue: 'Close',
}


const PFMCampaign = ({productName, submitAnswers, onCampaignEnd}) => {
  const [showThankYou, setShowThankYou] = useState(false)

  if (showThankYou) {
    return <Tasks tasks={[thankYouTask]} onTasksEnd={onCampaignEnd} />
  }

  const tasks = createPFMTasks(productName)
  return (
    <Tasks
      key={tasks.map(t => t.id).join('_')}
      tasks={tasks}
      onTasksEnd={(answers) => {
        submitAnswers(answers)
        setShowThankYou(true)
      }}
    />
  )
}

export default PFMCampaign
