import types from './types'
import status from './fetch-status'
import api from '../services/api'

const apiCall = (fn, ...args) => actionType => (dispatch) => {
  dispatch({
    type: actionType,
    status: status.startedFetching,
  })

  return fn(...args)
    .then(res => dispatch({
      type: actionType,
      status: status.successfullyFetched,
      payload: res,
    }))
    .catch(async (error) => {
      await dispatch({
        type: actionType,
        status: status.errorFetching,
        error,
      })
      throw error
    })
}

export const setAppId = appId => dispatch => dispatch({
  type: types.SET_APP_ID,
  payload: appId,
})


export const setUserId = userId => dispatch => dispatch({
  type: types.SET_USER_ID,
  payload: userId,
})

export const setIsVisible = isVisible => dispatch => dispatch({
  type: types.SET_IS_VISIBLE,
  payload: isVisible,
})

export const fetchWidget = userId => dispatch => dispatch(apiCall(() => api.getWidget(userId))(types.FETCH_APP_WIDGET))

export const submitAnswers = args => dispatch => dispatch(
  apiCall(() => api.submitAnswers(args))(types.SUBMIT_CAMPAIGN_ANSWERS),
)

export default {
  setAppId,
  setIsVisible,
  fetchWidget,
  submitAnswers,
}
