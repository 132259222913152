import actionTypes from '../actions/types'
import actionStatus from '../actions/fetch-status'

const initialState = {
  appId: null,
  userId: null,
  isVisible: false,
  widget: {
    isFetching: false,
    lastFetched: null,
    error: null,
    payload: null,
  },
}

const reduceApiAction = ({action, state, key}) => {
  switch (action.status) {
    case actionStatus.startedFetching:
      return {...state, [key]: {...state[key], isFetching: true, error: null}}
    case actionStatus.successfullyFetched:
      return {
        ...state,
        [key]: {
          ...state[key], error: null, isFetching: false, lastFetched: new Date(), payload: action.payload,
        },
      }
    case actionStatus.errorFetching:
      return {
        ...state,
        [key]: {...state[key], isFetching: false, error: action.error},
      }
    default:
      return state
  }
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APP_ID: {
      return {
        ...state,
        appId: action.payload,
      }
    }
    case actionTypes.SET_USER_ID: {
      return {
        ...state,
        userId: action.payload,
      }
    }
    case actionTypes.FETCH_APP_WIDGET: {
      return reduceApiAction({
        action,
        state,
        key: 'widget',
      })
    }
    case actionTypes.SET_IS_VISIBLE: {
      const event = {
        from: 'BRUTAL_FEEDBACK_WIDGET_EVENT',
        type: action.type,
        payload: action.payload,
      }

      window.parent.postMessage(event, '*')

      return {
        ...state,
        isVisible: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default rootReducer
