import request from 'superagent'
import config from '../config'
import store from '../../store'

const getURI = path => config.apiHost + path

async function post({
  path, body, complete = false,
}) {
  const {appId, userId} = store.getState()

  const req = request
    .post(getURI(path))
    .set('appId', appId)
    .set('userId', userId)
    .send(body)

  if (complete) {
    return req
  }

  return req.then(res => res.body)
}

async function get({
  path, complete = false,
}) {
  const {appId, userId} = store.getState()

  const req = request
    .get(getURI(path))
    .set('AppId', appId)
    .set('UserId', userId)

  if (complete) {
    return req
  }

  return req.then(res => res.body)
}

async function getWidget() {
  return get({
    path: '/sdk/widget/active_campaign',
  })
}

async function submitAnswers({answers, campaignId}) {
  return post({
    path: `/sdk/widget/campaigns/${campaignId}/answers`,
    body: {
      answers,
    },
  })
}

export default {
  getWidget,
  submitAnswers,
}
